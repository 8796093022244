import React from 'react'
import { Link, navigate, useStaticQuery, graphql } from 'gatsby'
import styled, { css, ThemeProvider } from 'styled-components'
import { Layout, Block, MetaTags } from '../components'
import { getLuminance } from 'polished'
import logo from '../assets/images/logo.svg'

import { container, padding, bgIcon, bgImage, hoverState } from '../styles/global';
import { grey, bronze, yellow, brown } from '../styles/colors';
import themes, { getThemeData } from '../styles/themes';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

class Category extends React.Component {

	state = {

	}

	componentWillMount = () => {
		//console.log(this.props.pageContext)
		this.setState({
            data: this.props.pageContext.data,     
            acf: JSON.parse(this.props.pageContext.data.acf_json),
            globalData: JSON.parse(this.props.pageContext.globalData.acf_json),
		})
	}

	renderHero = () => {
		const { data } = this.state;

		return (
			<Block
				layout={'heading'}
				text={data.title}	
				display={true}
				blockTheme={topBlocks}
				extraStyles={css`
					padding-top: 150px;
					height: 510px;
					box-sizing: border-box;
				`}
			/>
		)
	}

	resolveExtraBlockStyles = (block, i) => {
		return css`
			${i < 1 && block.acf_fc_layout == 'text_with_image' && `
				p {
					strong {
						font-size: 31.8px;
						line-height: 38.16px;
					}
				}

				* {
					column-gap: 70px;
				}
			`}

			${i == 1 && css`
				margin-bottom: 50px;
			`}
		`
	}

	resolveBlockTheme = (block, i) => {
        if (i < 2) return topBlocks    
        const themeBlocks = ['grid','text_with_image']
        if(themeBlocks.includes(block.acf_fc_layout)) return judgesTheme
	}

	renderBlocks = () => {
        const { blocks } = this.state.acf;

        console.log('blocks',blocks);
        
		return blocks && blocks.map((block, i) => {       
			return (
				<Block
					layout={block.acf_fc_layout}
					key={i}
					{...block}
					blockTheme={this.resolveBlockTheme(block, i)}
					extraStyles={this.resolveExtraBlockStyles(block, i)}
				/>
			)
		})
	}

	render() {       
        const { data, acf, globalData } = this.state;

        let meta_description = acf.meta_description
        if(meta_description=='') meta_description = globalData.meta_description_default

		return (
			<Layout
				theme={data.slug} 
				footerImage={acf.footer_bg_image && acf.footer_bg_image.sizes.xlarge}
			>
                <MetaTags
                    title={data.title}
                    description={meta_description}
                />
				<Wrapper>
					<Header
						showNav={true}
						showSubmit={true}
						fixed={true}
						theme={{
							colour: getLuminance(acf.background_colour) > 0.3 ? brown : yellow
						}}
					/>

					{this.renderHero()}
					{this.renderBlocks()}
				</Wrapper>
			</Layout>	
		)
	}
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	overflow-x: hidden;
`

const topBlocks = css`
	background: ${props => props.theme.background};

	* {
		color: ${props => props.theme.text};
		border-color: ${props => props.theme.text};
	}
`

const judgesTheme = css`
	* {
		color: ${props => props.theme.text};
		border-color: ${props => props.theme.text};
	}
`
export default Category

